export const ranks = [
  // Apex
  'apexRookie',
  'apexBronze',
  'apexSilver',
  'apexGold',
  'apexPlatinum',
  'apexDiamond',
  'apexMaster',
  'apexPredator',
  // ApexMobile
  'apexMobileRookie',
  'apexMobileBronze',
  'apexMobileSilver',
  'apexMobileGold',
  'apexMobilePlatinum',
  'apexMobileDiamond',
  'apexMobileMaster',
  'apexMobilePredator',
  // VALORANT
  'valorantIron',
  'valorantBronze',
  'valorantSilver',
  'valorantGold',
  'valorantPlatinum',
  'valorantDiamond',
  'valorantImmortal',
  'valorantRadiant',
  // Fortnite
  'fortniteDivision1',
  'fortniteDivision2',
  'fortniteDivision3',
  'fortniteDivision4',
  'fortniteDivision5',
  'fortniteDivision6',
  'fortniteDivision7',
  'fortniteDivision8',
  'fortniteDivision9',
  'fortniteDivision10',
  // DbD
  'dbdAsh',
  'dbdBronze',
  'dbdSilver',
  'dbdGold',
  'dbdIridescent',
  //DbDMobile
  'dbdMobileAsh',
  'dbdMobileBronze',
  'dbdMobileSilver',
  'dbdMobileGold',
  'dbdMobileIridescent',
  //pokemonUnite
  'pokemonUniteBeginner',
  'pokemonUniteSuper',
  'pokemonUniteHyper',
  'pokemonUniteElite',
  'pokemonUniteExpert',
  'pokemonUniteMaster',
  //identityV
  'identityV1',
  'identityV2',
  'identityV3',
  'identityV4',
  'identityV5',
  'identityV6',
  'identityV7',
  'identityV8',
  //lol
  'lolIron',
  'lolBronze',
  'lolSilver',
  'lolGold',
  'lolPlatinum',
  'lolDiamond',
  'lolMaster',
  'lolGrandMaster',
  'lolChallenger',
  //monsterhunterrise
  'monsterhunterriseHr3',
  'monsterhunterriseHr8',
  'monsterhunterriseHr20',
  'monsterhunterriseHr30',
  'monsterhunterriseHr40',
  'monsterhunterriseHr45',
  'monsterhunterriseHr50',
  'monsterhunterriseHr100',
  'monsterhunterriseHrOver100',
  //monsterHunterRiseSunBreak
  'monsterHunterRiseSunBreakMr10',
  'monsterHunterRiseSunBreakMr20',
  'monsterHunterRiseSunBreakMr30',
  'monsterHunterRiseSunBreakMr50',
  'monsterHunterRiseSunBreakMr70',
  'monsterHunterRiseSunBreakMr100',
  'monsterHunterRiseSunBreakMrOver100',
  // 荒野行動
  'knivesOutBronze',
  'knivesOutSilver',
  'knivesOutGold',
  'knivesOutPlatinum',
  'knivesOutDiamond',
  'knivesOutMaster',
  'knivesOutKing',
  //overwatch
  'overwatchBronze',
  'overwatchSilver',
  'overwatchGold',
  'overwatchPlatinum',
  'overwatchDiamond',
  'overwatchMaster',
  'overwatchGrandMaster',
  'overwatchTop500',
  //overwatch2
  'overwatch2Bronze',
  'overwatch2Silver',
  'overwatch2Gold',
  'overwatch2Platinum',
  'overwatch2Diamond',
  'overwatch2Master',
  'overwatch2GrandMaster',
  'overwatch2Top500',
  //r6s
  'r6sCopper',
  'r6sBronze',
  'r6sSilver',
  'r6sGold',
  'r6sPlatinum',
  'r6sEmerald',
  'r6sDiamond',
  'r6sChampion',

  //rocketLeague
  'rocketLeagueUnranked',
  'rocketLeagueBronze',
  'rocketLeagueSilver',
  'rocketLeagueGold',
  'rocketLeaguePlatinum',
  'rocketLeagueDiamond',
  'rocketLeagueChampion',
  'rocketLeagueGrandChampion',
  'rocketLeagueSupersonicLegend',
  // こだわらない
  'any',
] as const

export type Rank = (typeof ranks)[number]

export const getRankDisplayName = (rank: Rank) => {
  switch (rank) {
    // Apex
    case 'apexRookie':
      return 'ルーキー'
    case 'apexBronze':
      return 'ブロンズ'
    case 'apexSilver':
      return 'シルバー'
    case 'apexGold':
      return 'ゴールド'
    case 'apexPlatinum':
      return 'プラチナ'
    case 'apexDiamond':
      return 'ダイヤ'
    case 'apexMaster':
      return 'マスター'
    case 'apexPredator':
      return 'プレデター'
    // ApexMobile
    case 'apexMobileRookie':
      return 'ルーキー'
    case 'apexMobileBronze':
      return 'ブロンズ'
    case 'apexMobileSilver':
      return 'シルバー'
    case 'apexMobileGold':
      return 'ゴールド'
    case 'apexMobilePlatinum':
      return 'プラチナ'
    case 'apexMobileDiamond':
      return 'ダイヤ'
    case 'apexMobileMaster':
      return 'マスター'
    case 'apexMobilePredator':
      return 'プレデター帯'
    // Valorant
    case 'valorantIron':
      return 'アイアン'
    case 'valorantBronze':
      return 'ブロンズ'
    case 'valorantSilver':
      return 'シルバー'
    case 'valorantGold':
      return 'ゴールド'
    case 'valorantPlatinum':
      return 'プラチナ'
    case 'valorantDiamond':
      return 'ダイヤ'
    case 'valorantImmortal':
      return 'イモータル'
    case 'valorantRadiant':
      return 'レディアント'

    // Fortnite
    case 'fortniteDivision1':
      return 'ディビジョン1'
    case 'fortniteDivision2':
      return 'ディビジョン3'
    case 'fortniteDivision3':
      return 'ディビジョン3'
    case 'fortniteDivision4':
      return 'ディビジョン4'
    case 'fortniteDivision5':
      return 'ディビジョン5'
    case 'fortniteDivision6':
      return 'ディビジョン6'
    case 'fortniteDivision7':
      return 'ディビジョン7'
    case 'fortniteDivision8':
      return 'ディビジョン8'
    case 'fortniteDivision9':
      return 'ディビジョン9'
    case 'fortniteDivision10':
      return 'ディビジョン10'

    // DbD
    case 'dbdAsh':
      return '灰帯'
    case 'dbdBronze':
      return '銅帯'
    case 'dbdSilver':
      return '銀帯'
    case 'dbdGold':
      return '金帯'
    case 'dbdIridescent':
      return '彩帯'
    //DbDMobile
    case 'dbdMobileAsh':
      return '灰帯'
    case 'dbdMobileBronze':
      return '銅帯'
    case 'dbdMobileSilver':
      return '銀帯'
    case 'dbdMobileGold':
      return '金帯'
    case 'dbdMobileIridescent':
      return '彩帯'
    //pokemonUnite
    case 'pokemonUniteBeginner':
      return 'ビギナー'
    case 'pokemonUniteSuper':
      return 'スーパー'
    case 'pokemonUniteHyper':
      return 'ハイパー'
    case 'pokemonUniteElite':
      return 'エリート'
    case 'pokemonUniteExpert':
      return 'エキスパート'
    case 'pokemonUniteMaster':
      return 'マスター'
    //identityV
    case 'identityV1':
      return '1段'
    case 'identityV2':
      return '2段'
    case 'identityV3':
      return '3段'
    case 'identityV4':
      return '4段'
    case 'identityV5':
      return '5段'
    case 'identityV6':
      return '6段'
    case 'identityV7':
      return '7段'
    case 'identityV8':
      return '最高峰7段'
    //lol
    case 'lolIron':
      return 'アイアン'
    case 'lolBronze':
      return 'ブロンズ'
    case 'lolSilver':
      return 'シルバー'
    case 'lolGold':
      return 'ゴールド'
    case 'lolPlatinum':
      return 'プラチナ'
    case 'lolDiamond':
      return 'ダイヤモンド'
    case 'lolMaster':
      return 'マスター'
    case 'lolGrandMaster':
      return 'グランドマスター'
    case 'lolChallenger':
      return 'チャレンジャー'
    //monsterhunterrise
    case 'monsterhunterriseHr3':
      return 'HR1〜3'
    case 'monsterhunterriseHr8':
      return 'HR4〜8'
    case 'monsterhunterriseHr20':
      return 'HR8〜20'
    case 'monsterhunterriseHr30':
      return 'HR21〜30'
    case 'monsterhunterriseHr40':
      return 'HR31〜40'
    case 'monsterhunterriseHr45':
      return 'HR41〜45'
    case 'monsterhunterriseHr50':
      return 'HR46〜50'
    case 'monsterhunterriseHr100':
      return 'HR51〜100'
    case 'monsterhunterriseHrOver100':
      return 'HR100〜'
    //monsterHunterRiseSunBreak
    case 'monsterHunterRiseSunBreakMr10':
      return 'MR1~10'
    case 'monsterHunterRiseSunBreakMr20':
      return 'MR10~20'
    case 'monsterHunterRiseSunBreakMr30':
      return 'MR20~30'
    case 'monsterHunterRiseSunBreakMr50':
      return 'MR30~50'
    case 'monsterHunterRiseSunBreakMr70':
      return 'MR50~70'
    case 'monsterHunterRiseSunBreakMr100':
      return 'MR70~100'
    case 'monsterHunterRiseSunBreakMrOver100':
      return 'MR100~'
    // 荒野行動
    case 'knivesOutBronze':
      return 'ブロンズ'
    case 'knivesOutSilver':
      return 'シルバー'
    case 'knivesOutGold':
      return 'ゴールド'
    case 'knivesOutPlatinum':
      return 'プラチナ'
    case 'knivesOutDiamond':
      return 'ダイヤ'
    case 'knivesOutMaster':
      return 'マスター'
    case 'knivesOutKing':
      return '荒野王者'
    //overwatch
    case 'overwatchBronze':
      return 'ブロンズ'
    case 'overwatchSilver':
      return 'シルバー'
    case 'overwatchGold':
      return 'ゴールド'
    case 'overwatchPlatinum':
      return 'プラチナ'
    case 'overwatchDiamond':
      return 'ダイヤモンド'
    case 'overwatchMaster':
      return 'マスター'
    case 'overwatchGrandMaster':
      return 'グランドマスター'
    case 'overwatchTop500':
      return 'TOP500'
    //overwatch2
    case 'overwatch2Bronze':
      return 'ブロンズ'
    case 'overwatch2Silver':
      return 'シルバー'
    case 'overwatch2Gold':
      return 'ゴールド'
    case 'overwatch2Platinum':
      return 'プラチナ'
    case 'overwatch2Diamond':
      return 'ダイヤモンド'
    case 'overwatch2Master':
      return 'マスター'
    case 'overwatch2GrandMaster':
      return 'グランドマスター'
    case 'overwatch2Top500':
      return 'TOP500'
    //R6S
    case 'r6sCopper':
      return 'コッパー'
    case 'r6sBronze':
      return 'ブロンズ'
    case 'r6sSilver':
      return 'シルバー'
    case 'r6sGold':
      return 'ゴールド'
    case 'r6sPlatinum':
      return 'プラチナ'
    case 'r6sEmerald':
      return 'エメラルド'
    case 'r6sDiamond':
      return 'ダイヤ'
    case 'r6sChampion':
      return 'チャンピオン'
    //Rocket League
    case 'rocketLeagueUnranked':
      return 'ランクなし'
    case 'rocketLeagueBronze':
      return 'ブロンズ'
    case 'rocketLeagueSilver':
      return 'シルバー'
    case 'rocketLeagueGold':
      return 'ゴールド'
    case 'rocketLeaguePlatinum':
      return 'プラチナ'
    case 'rocketLeagueDiamond':
      return 'ダイヤモンド'
    case 'rocketLeagueChampion':
      return 'チャンピオン'
    case 'rocketLeagueGrandChampion':
      return 'グランドチャンピオン'
    case 'rocketLeagueSupersonicLegend':
      return '超音速レジェンド'
    // Other
    case 'any':
      return 'こだわらない'
  }
}
