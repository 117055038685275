import firebase from 'firebase/app'
import { Chat } from './Chat'
import { GameTitle } from './GameTitle'
export const COLLECTION_NAME_ROOMS = 'rooms'
export enum RoomStatus {
  // eslint-disable-next-line no-unused-vars
  open = 'open',
  // eslint-disable-next-line no-unused-vars
  close = 'close',
}
export interface Room {
  roomId: string
  userIds: string[]
  matchedUserIds: string[]
  matchedUserProfileImgUrls: string[]
  hostUserId: string
  status: RoomStatus
  gameTitle: GameTitle
  chats: Chat[]
  createAt: firebase.firestore.Timestamp
}
