export const voiceChats = ['on', 'off', 'any'] as const

export type VoiceChat = typeof voiceChats[number]

export const getVoiceChatDisplayName = (vc: VoiceChat) => {
  switch (vc) {
    case 'on':
      return 'あり'
    case 'off':
      return 'なし'
    case 'any':
      return 'こだわらない'
  }
}
