import firebase from 'firebase/app'
export const COLLECTION_NAME_BOSYUS = 'bosyus'
export enum BosyuStatus {
  // eslint-disable-next-line no-unused-vars
  open = 'open',
  // eslint-disable-next-line no-unused-vars
  close = 'close',
}

export interface Bosyu {
  bosyuId: string
  appInstanceId: string
  userId: string
  userName: string
  profileImgUrl: string
  ogpImageUrl: string
  numOfCurrentRecruitedUsers: number
  numOfRecruitingUsers: number
  bosyuGameTitle: string
  userDevice: 'ios' | 'web'
  userGender: 'other' | 'male' | 'female'
  userRatings: number
  userNumOfReviews: number
  isPremium: boolean
  membershipDurationPeriod: number
  userBirthYear: number // ex: 2002, 1993
  bosyuPlatforms: string[]
  bosyuPlatformsMap: Record<string, true>
  bosyuGender: 'onlyFemale' | 'any'
  bosyuScope: 'friends' | 'any'
  bosyuAgeRange:
    | 'twentyYearsOldOrOver'
    | 'twentyFifthYearsOldOrOver'
    | 'closeInAge'
    | 'any'
  bosyuFeatures: string[]
  bosyuGameModes: string[]
  bosyuRanks: string[]
  bosyuPlayStyle: string
  bosyuPlayStyles: string[]
  bosyuVoiceChats: string[]
  bosyuVC: 'any' | string // TODO
  bosyuRating: string
  userPlatforms: string[]
  userConversations: string[] // TODO(toco): これ何？
  isVisibleOnTimeline: boolean
  isAutoMatchEnabled: boolean
  title: string
  bosyuMemo: string
  fuids: string[] // friend user ids
  buids: string[] // blocking user ids
  bbyuids: string[] // blocked by user ids
  cfuids: string[] // close friend user ids
  userFeatures: string[]
  status: BosyuStatus
  userLangs: Array<'ja' | 'en'>
  createAt: firebase.firestore.Timestamp
}
