export const platforms = [
  'pc',
  'playStation',
  'xbox',
  'switch',
  'mobile',
] as const

export type Platform = typeof platforms[number]

export const getPlatformDisplayName = (platform: Platform) => {
  switch (platform) {
    case 'pc':
      return 'PC'
    case 'playStation':
      return 'PlayStation'
    case 'xbox':
      return 'Xbox'
    case 'switch':
      return 'Switch'
    case 'mobile':
      return 'モバイル'
  }
}

export const getPlatformImageUrl = (platform: Platform) => {
  switch (platform) {
    case 'switch':
      return `/static/images/platforms/icn_nintendoSwitch.png`
    default:
      return `/static/images/platforms/icn_${platform}.png`
  }
}
