export const gameModes = [
  // Apex
  'apexCasual',
  'apexRank',
  'apexArena',
  'apexArenaRank',
  'apexFiringRange',
  'apexEvent',
  // ApexMobile
  'apexMobileCasual',
  'apexMobileRank',
  'apexMobileFiringRange',
  'apexMobileEvent',
  // VALORANT
  'valorantUnrate',
  'valorantCompetitive',
  'valorantCustom',
  // Fortnite
  'fortniteCreative',
  'fortniteDuo',
  'fortniteTrio',
  'fortniteSquad',
  'fortniteArena',
  // ポケモンアルセウス
  'pokemonArceusExchange',
  // ダイパリメイク
  'pokemonBrilliantDiamondShiningPearlExchange',
  'pokemonBrilliantDiamondShiningPearlBattle',
  // splatoon3
  'splatoon3TurfWar',
  'splatoon3SalmonRun',
  'splatoon3AnarchyBattles',
  'splatoon3Event',
  // プロセカ
  'proSekaFree',
  'proSekaVeteran',
  //destiny2
  'destiny2Explore',
  'destiny2PvP',
  'destiny2Corp',
  'destiny2Raid',
  'destiny2Other',
  // こだわらない
  'any',
] as const

export type GameMode = typeof gameModes[number]

export const getGameModeDisplayName = (gameMode: GameMode) => {
  switch (gameMode) {
    // Apex
    case 'apexCasual':
      return 'カジュアル'
    case 'apexRank':
      return 'ランク'
    case 'apexArena':
      return 'アリーナ'
    case 'apexArenaRank':
      return 'アリーナランク'
    case 'apexFiringRange':
      return '射撃訓練所'
    case 'apexEvent':
      return 'イベント'
    // ApexMobile
    case 'apexMobileCasual':
      return 'カジュアル'
    case 'apexMobileRank':
      return 'ランク'
    case 'apexMobileFiringRange':
      return '射撃訓練所'
    case 'apexMobileEvent':
      return 'イベント'
    //valorant
    case 'valorantUnrate':
      return 'アンレ'
    case 'valorantCompetitive':
      return 'コンペ'
    case 'valorantCustom':
      return 'カスタム'
    //fortnite
    case 'fortniteCreative':
      return 'クリエイティブ'
    case 'fortniteDuo':
      return 'デュオ'
    case 'fortniteTrio':
      return 'トリオ'
    case 'fortniteSquad':
      return 'スクワッド'
    case 'fortniteArena':
      return 'アリーナ'
    //pokemonArceus
    case 'pokemonArceusExchange':
      return 'ポケモン交換'
    case 'pokemonBrilliantDiamondShiningPearlExchange':
      return 'ポケモン交換'
    case 'pokemonBrilliantDiamondShiningPearlBattle':
      return 'ポケモンバトル'
    //splatoon3
    case 'splatoon3TurfWar':
      return 'ナワバリバトル'
    case 'splatoon3SalmonRun':
      return 'サーモンラン'
    case 'splatoon3AnarchyBattles':
      return 'バンカラマッチ'
    case 'splatoon3Event':
      return 'イベント'
    //proSeka
    case 'proSekaFree':
      return 'フリールーム'
    case 'proSekaVeteran':
      return 'ベテランルーム'
    //Destiny2
    case 'destiny2Explore':
      return '探索'
    case 'destiny2PvP':
      return 'PVP'
    case 'destiny2Corp':
      return '協力ミッション'
    case 'destiny2Raid':
      return 'レイド'
    case 'destiny2Other':
      return 'その他'
    case 'any':
      return 'こだわらない'
  }
}
