import { GameTitle } from './GameTitle'
import { Template } from './Template'
import firebase from 'firebase/app'
export const COLLECTION_NAME_PROFILES = 'profiles'
export type Profiles = {
  id: string
  templateId: Template
  uid: string
  gameTitle: GameTitle
  accountId: string
  name: string
  sex: 0 | 1 | 2
  imgId: string
  playStyle?: string
  platform: Array<0 | 1 | 2>
  voiceChat: Array<0 | 1 | 2 | 3 | 4>
  playTimeWeekDayBegin: number
  playTimeWeekDayEnd: number
  playTimeWeekEndBegin: number
  playTimeWeekEndEnd: number
  apexFavoriteWeapon?: string
  apexMaxDamage?: number
  apexKD?: number
  apexRank?: string
  apexLegends?: number[]
  hitokoto?: string
  createAt: firebase.firestore.Timestamp
  creatorTwitterId?: string
}
