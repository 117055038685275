import { GameTitle } from './clients/firebase/firestore/GameTitle'

// トップページに表示されるテンプレートを定義
export const TOP_PAGE_TEMPLATE: {
  game: GameTitle
  imagePath: string
  alt: string
  link: string
}[] = [
  {
    game: 'apex',
    imagePath: `/static/images/profiles/apex1/0-min.jpg`,
    alt: 'Apex Legends自己紹介カード',
    link: 'me/profiles/create/apex',
  },
  {
    game: 'codBo6',
    imagePath: `/static/images/profiles/codBo6_1/0.jpeg`,
    alt: '【CodBo6】自己紹介カード作成ツール【CoDブラックオプス6】',
    link: 'me/profiles/create/codBo6',
  },
  {
    game: 'valorant',
    imagePath: `/static/images/profiles/valorant7/0-min.jpg`,
    alt: 'Valorant(ヴァロラント) 自己紹介カード',
    link: 'me/profiles/create/valorant',
  },
  {
    game: 'splatoon3',
    imagePath: `/static/images/profiles/splatoon3_6/0.jpeg`,
    alt: 'スプラトゥーン3 自己紹介カード',
    link: 'me/profiles/create/splatoon3',
  },
  {
    game: 'genshin',
    imagePath: `/static/images/profiles/genshin2/4-min.png`,
    alt: '【原神】かわいい自己紹介カード',
    link: 'me/profiles/create/genshin',
  },
  {
    game: 'myHeroAcademiaUltraRumble',
    imagePath: `/static/images/profiles/myHeroAcademiaUltraRumble1/0.jpeg`,
    alt: 'ヒロアカUR自己紹介カード',
    link: 'me/profiles/create/myHeroAcademiaUltraRumble',
  },
  {
    game: 'overwatch2',
    imagePath: `/static/images/profiles/overwatch2_1/0.jpeg`,
    alt: '【Overwatch2(オーバーウォッチ2)】自己紹介カード作成ツール',
    link: 'me/profiles/create/overwatch2',
  },
  // {
  //   game: 'blueProtocol',
  //   imagePath: `/static/images/profiles/blueProtocol1/0.png`,
  //   alt: '【ブループロトコル(BLUE PROTOCOL)】自己紹介カード作成ツール',
  //   link: 'me/profiles/create/blueProtocol',
  // },

  {
    game: 'dbd',
    imagePath: `/static/images/profiles/dbd1/0-min.jpeg`,
    alt: '【デドバ(DbD)】自己紹介カード',
    link: 'me/profiles/create/dbd',
  },
  {
    game: 'pazudora',
    imagePath: `/static/images/profiles/pazudora1/0-min.jpeg`,
    alt: '【パズドラ】自己紹介カード作成ツール',
    link: 'me/profiles/create/pazudora',
  },
  {
    game: 'codMw2',
    imagePath: `/static/images/profiles/codMw2_1/0.jpeg`,
    alt: '【CodMW2】自己紹介カード作成ツール【Codモダンウォーフェア2】',
    link: 'me/profiles/create/codMw2',
  },
  {
    game: 'codMw3',
    imagePath: `/static/images/profiles/codMw3_1/0.jpeg`,
    alt: '【CodMW3】自己紹介カード作成ツール【Codモダンウォーフェア3】',
    link: 'me/profiles/create/codMw3',
  },
  {
    game: 'bf2042',
    imagePath: `/static/images/profiles/bf2042_1/0.png`,
    alt: '【BF2042(バトルフィールド2042)】自己紹介カード作成ツール',
    link: 'me/profiles/create/bf2042',
  },
  {
    game: 'amongus',
    imagePath: `/static/images/profiles/amongus1/0.png`,
    alt: '【AmongUs(アマングアス)】自己紹介カード',
    link: 'me/profiles/create/amongus',
  },
  {
    game: 'fallguys',
    imagePath: `/static/images/profiles/fallguys1/0.jpeg`,
    alt: '【フォールガイズ(Fall Guys)】自己紹介カード',
    link: 'me/profiles/create/fallguys',
  },
  {
    game: 'pokemonUnite',
    imagePath: `/static/images/profiles/pokemonUnite1/0-min.jpeg`,
    alt: '【ポケモンユナイト(Pokemon Unite)】自己紹介カード',
    link: 'me/profiles/create/pokemonUnite',
  },
  {
    game: 'monsterHunterRiseSunBreak',
    imagePath: `/static/images/profiles/monsterhunterrise1/0.jpeg`,
    alt: '【モンハンライズ】自己紹介カード作成ツール',
    link: 'me/profiles/create/monsterhunterrise',
  },
  // {
  //   game: 'gundamEvolution',
  //   imagePath: `/static/images/profiles/gundamEvolution1/0.png`,
  //   alt: '【ガンエボ】自己紹介カード作成ツール【ガンダムエボリューション】',
  //   link: 'me/profiles/create/gundamEvolution',
  // },
]
