import firebase from 'firebase/app'

export const COLLECTION_PICKED_ITEMS = 'pickedItems'

export type PickedItem = {
  pickedItemId: string
  title: string
  description: string
  imageUrl: string
  url: string
  createAt: firebase.firestore.Timestamp
}
